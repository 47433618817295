import React from "react"
import UseCase from "./UseCase";

const useCases = [
  {
    body: (
      <p>
        <b>Contactless and efficient</b> queue <br />
    management in your venues
      </p>
    ),
    link: ''
  },
  {
    body: (
      <p>
        Notification tools to <b>manage and exceed </b>
        <br />
        <b>customer </b>expectations in venue
      </p>
    ),
    link: ''
  },
  {
    body: (
      <p>
        Increase <b>customer yield</b>, <br />
        <b>dwell time</b> and repeat business
      </p>
    ),
    link: ''
  },
  {
    body: (
      <p>
        Reduce your <b>operational</b> and <br />
        <b>hardware spend </b>
      </p>
    ),
    link: ''
  }
]

const UseCases = () => {
  return (
    <div className="loadmore">
      <div className="loadmore-content">
        {useCases.map(({ body, link }, i) => <UseCase key={`use-case-${i}`} body={body} link={link} />)}
      </div>
    </div>
  )
}
export default UseCases
