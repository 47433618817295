import React from "react"

const MeetKyuBanner = () => {
  return (
    <section>
      <div className="meet-kyu">
        <div className="meet-kyu-div">
          <h1 className="meet-kyu-h1">
            Meet <span className="meet-kyu-span-1">Kyuu</span>
          </h1>
          <p className="meet-kyu-p">
            Traditional queueing systems use outdated hardware. They're expensive
            to <br />
            maintain and difficult to sanitise during service.
          </p>
          <p className="meet-kyu-p">
            It's time for a <b>revolution.</b> <br />
            We're <b>evolving</b> ordering systems to make them as they should
            be:
            <br />
            <b>efficient; contacless</b> and <b>cost effective.</b>
          </p>
        </div>
      </div>
    </section>
  )
}
export default MeetKyuBanner
