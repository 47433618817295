import React from "react"

const KyuBetterWayBanner = () => {
  return (
    <div className="kyu-better-way">
      <p className="Kyu-better-way-p">
        <b>Kyuu. </b> A better way <br />
        to connect with your customers.
      </p>
      <div className="kyu-better-way-img"></div>
    </div>
  )
}
export default KyuBetterWayBanner
