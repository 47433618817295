import { Link } from 'gatsby';
import React from 'react';


const UseCase = ({ body, link = "/" }) => (
    <div className="loadmore-phara">
        {body}
        <Link to={link} className="loadmore-button">Load more</Link>
    </div>
)

export default UseCase;