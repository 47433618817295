import React from "react"

const Launchingsoon = () => {
  return (
    <section className="launchingsoon">
      <div className="launchingsoon-wrap">
        <div className="launchingsoon-div">
          <h3 className="launchingsoon-h3">LAUNCHING SOON</h3>
          <p className="launchingsoon-p">
            <span className="launchingsoon-span">
              Kyuu is currently in trial mode.
            </span>{" "}
            <br />
            To register your interest,
            <br />
            please contact us.
          </p>
        </div>
      </div>
    </section>
  )
}
export default Launchingsoon
