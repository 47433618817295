import React from "react"
import Layout from "../components/Layout"
import MeetKyuBanner from "../components/MeetkyuBanner"
import KyuBetterWayBanner from "../components/KyuBetterWayBanner"
import UseCases from "../components/UseCases"
import Getintouch from "../components/Getintouch"
import Launchingsoon from "../components/Launchingsoon"
import ContactForm from "../components/Contactform"

const IndexPage = () => {
  const isDesktop = typeof window !== "undefined" && window.innerWidth >= 1200
  const desktopLayout = (
    <Layout isHomePage={true}>
      <MeetKyuBanner></MeetKyuBanner>
      <KyuBetterWayBanner></KyuBetterWayBanner>
      <UseCases></UseCases>
      <Getintouch></Getintouch>
      <div className="kyu-contact-div">
        <ContactForm></ContactForm>
      </div>
      <Launchingsoon></Launchingsoon>
    </Layout>
  )

  const mobileLayout = (
    <Layout isHomePage={true}>
      <MeetKyuBanner></MeetKyuBanner>
      <KyuBetterWayBanner></KyuBetterWayBanner>
      <UseCases></UseCases>
      <Getintouch></Getintouch>
      <div className="kyu-contact-div">
        <ContactForm></ContactForm>
      </div>
      <Launchingsoon></Launchingsoon>
    </Layout>
  )
  return isDesktop ? desktopLayout : mobileLayout
}

export default IndexPage
