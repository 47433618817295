import React from "react"
import { useState } from "react"

const ContactForm = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [message, setMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [successMessage, setSuccessMessage] = useState()

  const handleSubmit = async () => {
    setErrorMessage(undefined)
    if (!name) {
      setErrorMessage("Please enter your name")
      return
    }
    if (!email) {
      setErrorMessage("Please enter your email")
      return
    }
    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
      if (!pattern.test(email)) {
        setErrorMessage("Please enter a valid email")
        return
      }
      if (!message) {
        setErrorMessage("Please enter your message")
        return
      }
    }

    try {
      const res = await fetch(
        process.env.GATSBY_API_URL,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, message }),
        }
      )
      await res.json()
      setEmail("")
      setName("")
      setMessage("")
      setErrorMessage(undefined)
      setSuccessMessage("Your reponse is submitted.")
      setTimeout(() => {
        setSuccessMessage("")
      }, 5000)
    } catch (e) {
      console.log(e)
    }
  }

  const errorView = errorMessage ? (
    <div className="form-error">{errorMessage}</div>
  ) : null


  const successView = successMessage ? (
    <div className="form-success-msg">{successMessage}</div>
  ) : null

  return (
    <form className="contactform">
      {errorView}
      {successView}
      <div className="form-group">
        <label name="name" htmlFor="name">
          Your Name
        </label>
        <input
          id="name"
          value={name}
          className="contactform-name"
          onChange={e => setName(e.target.value)}
          type="text"
          placeholder="Enter Your Name"
        />
      </div>
      <div className="form-group">
        <label name="email" htmlFor="name">
          Email
        </label>
        <input
          id="email"
          className="contactform-email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Enter Your Email"
        />
      </div>
      <div className="form-group">
        <label name="message" htmlFor="message">
          Message
        </label>
        <textarea
          id="message"
          className="contactform-msg"
          type="text"
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Enter Your Message"
        />
      </div>
      <div className="form-group">
        <button
          type="button"
          className="contactform-button"
          onClick={handleSubmit}
        >
          Send
        </button>
      </div>
    </form>
  )
}

export default ContactForm;
