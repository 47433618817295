import React from "react"
import { Element, animateScroll as scroll } from "react-scroll"
import TopImage from "../../static/Images/toTop.png"

const Getintouch = () => {
  const scrollToTop = () => {
    scroll.scrollToTop()
  }
  return (
    <React.Fragment>
      <Element id="kyu-form" name="kyu-form">
        <section>
          <div>
            <img
              src={TopImage}
              alt=""
              className="back-to-top"
              onClick={scrollToTop}
            ></img>
          </div>
          <div className="getintouch">
            <div className="getintouch-div">
              <h3 className="getintouch-h3">GET IN TOUCH</h3>
              <p className="getintouch-p">
                Chat with us today to find out how <br />
                Kyuu can add value to your <br />
                business
              </p>
            </div>
          </div>
        </section>
      </Element>
    </React.Fragment>
  )
}
export default Getintouch
